import '../../styles/carrouselProducts.scss';
import 'react-multi-carousel/lib/styles.css';
import { Carrousel } from './carrousel';

export const CarrouselProducts = () => {
  return <div className='carrousel-products'>
    <div className='carrousel-products-container'>
      <h2 data-aos="zoom-up">explore the colection</h2>
      <div data-aos="zoom-up">
      <Carrousel />
      </div>
    </div>
  </div>
}