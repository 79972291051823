import { Link } from "react-router-dom";
import { Header } from "../components/header";
import '../styles/support.scss';

export const Support = () =>{
  window.scrollTo(0,0);

  return <div>
    <Header />
    <div className="support">
      <h3>Contact us</h3>
      <p>We will be pleased to assist you with any question or inquiry regarding our company and our products.</p>
      <div className="support-box">
        <h4>ONLINE PRODUCTS</h4>
        <p>If you are interested in our online catalog, you can contact us by:</p>
        <p>Email: info@tommystuxedos.com</p>
      </div>
      <div className="support-box">
        <h4>PHYSICAL STORES</h4>
        <p>If you are interested to take a contact with our stores, you can contact:</p>
        <p>Telephone: 305.553.4416 (Miami location)</p>
        <p>Telephone: 305.512.9122 (Miami Lakes / Hialeah Location)</p>
        <p>Monday to Friday: open from 10am to 6pm - Saturdays: open from 10am to 4pm</p>
        <Link to='/our-stores' className="support-link-stores">Find store</Link>
      </div>
      <div className="support-box last-box">
        <h4>SOCIAL</h4>
        <a href='https://www.facebook.com/tommys.tuxedos.9' target="_blank" className="support-link-social">Facebook: @tommys.tuxedos.9</a>
        <a href='https://www.instagram.com/tommystuxedos/' target="_blank" className="support-link-social">Instagram: @tommystuxedos</a>
      </div>
      <div className="rights">
      Copyright © 2021 Tommys Tuxedos - All Rights Reserved
      </div>
    </div>
  </div>
}