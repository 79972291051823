import { About } from "../components/about"
import { CarrouselProducts } from "../components/carrousel/carrouselProducts"
import { Contact } from "../components/contact"
import { Cover } from "../components/cover"
import { Faqs } from "../components/faqs/faqs"
import { Footer } from "../components/footer"
import { Header } from "../components/header"
import { HowItWorks } from "../components/howItWorks"
import { PopUpMessage } from "../components/PopUpMessage"
import { Sale } from "../components/sale"
import { SupportButton } from "../components/supportButton"
//import { Testimonies } from "../components/testimonies"

export const Home = () => {
  window.scrollTo(0,0);

  return <div>
    <Sale/> 
    <Header background={true}/>
    <SupportButton />
    <Cover />
    <CarrouselProducts />
    <HowItWorks />
    <About />
    {/*<Testimonies />*/}
    <Faqs />
    <Contact />
    <Footer />
  </div>
}