import '../../styles/stores.scss';
import { Location } from './location';

export const Stores = () => {
  return <div className='stores'>
    <h3>our stores</h3>
    <div className='our-stores'>
      <Location city='Miami Location' 
       adress='890 S.W. 87 Avenue, Miami, FL 33174 - Westchester local 8' 
       number='305.553.4416' 
       linkBlue='GO WITH GOOGLE MAPS'
       arrow={true}
       linkMaps="https://www.google.com.ar/maps/place/890+SW+87th+Ave,+Miami,+FL+33174,+EE.+UU./@25.7612901,-80.3388451,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9b8e38c5a47d3:0xf758b7409c570ac0!8m2!3d25.7612853!4d-80.3366564"
      />
      <Location city='Miami Lakes / Hialeah Location' 
      adress='17690 N.W. 78 Avenue, Hialeah, FL 33015 - Miami Lake local 101' 
      number='305.512.9122'
      linkBlue='GO WITH GOOGLE MAPS'
      arrow={true}
      linkMaps='https://www.google.com.ar/maps/place/17690+NW+78th+Ave,+Hialeah,+FL+33015,+EE.+UU./@25.9342781,-80.3297363,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9a4e6c7d9c9a5:0x37bb5335108d1b66!8m2!3d25.9342733!4d-80.3275476'
      />
    </div>
  </div>
}