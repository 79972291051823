import { Faq } from "../components/faqs/faq";
import { Header } from "../components/header";
import '../styles/faqs.scss';

export const FaqsPage = () =>{
  window.scrollTo(0,0);
  
  return <div>
    <Header />
    <div className="faqs-page">
      <h3>faqs</h3>
      <div className="faqs-full-list">
        <Faq question='Do we have to pay a deposit?' answer='50 % of the total amount is required up front.' />
        <Faq question='Have you got plus size clothing?' answer='All sizes are available up to XXL' />
        <Faq question='How far in advance should I make a reservation?' answer='From 10 to 15 days before you need it' />
        <Faq question='Is it possible to rent a tuxedo the day before I need it?' answer='Of course, it is. We have stock and professional tailors to make it possible, but it would be advisable to do it in advance.' />
        <Faq question='Do you deliver the suit?' answer='It depends on the area.' />
        <Faq question='Have you got all sizes to rent or sell?' answer='We have. You don’t have to pay additional costs to get a custom-made tuxedo' />
        <Faq question='Can I rent clothes for kids and teens?' answer='You can, from  two-year-old toddlers up to twenty-year-old young adults' />
        <Faq question='Do I have to take the tuxedo to the dry cleaner’s after using it?' answer='It isn’t necessary to do that, the cleaning is included in the price.You just have to bring it back with the hanger and the cover.' />
        <Faq question='What happens if I lose or tear the suit?' answer='Extra cost will be applied to mend  or replace it.' />
        <Faq question='Can I rent the tuxedo for a longer period?' answer='If you need it for a longer period  of time because you have to travel it can be arranged and we have special prices for those occasions' />
        <Faq question='What happens if I don’t return the tuxedo on time?' answer='$30 a day will be applied' />
      </div>
    </div>
  </div>
}