import '../styles/contact.scss';
import { Form } from './form';
import { Stores } from './stores/stores';

export const Contact = () => {
  return <div className='contact'>
    <div id='contact-us'></div>
    <div className='contact-container'>
      <div data-aos="zoom-in" data-aos-delay="400">
        <Form />
      </div>
      <div data-aos="zoom-in" data-aos-delay="600">
        <Stores />
      </div>
    </div>
  </div>
}