import '../../styles/stores.scss';
import location from '../../assets/location.svg';
import { LinkBlue } from '../linkBlue';


export const Location = (props: any) => {
  return <div className='location'>
    <h4>{props.city}</h4>
    <div className='adress'>
      <img src={location} alt="" />
      <p>{props.adress}</p>
    </div>
    <span>{props.number}</span>
   <LinkBlue linkBlue={props.linkBlue} arrow={props.arrow} linkMaps={props.linkMaps}/>
  </div>
}