import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Catalog } from '../pages/catalog';
import { Support } from '../pages/support';
import { DetailPage } from '../pages/detailPage';
import { FaqsPage } from '../pages/faqs';
import { Home } from '../pages/home';
import { OurStores } from '../pages/ourStores';
import { TermsConditions } from './temsConditions/TermsConditions';
import { TuxBox } from '../pages/tuxBox';

export function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home}></Route>
      <Route path="/catalog" exact component={Catalog}></Route>
      <Route path="/tuxedos-box" exact component={TuxBox}></Route>
      <Route path="/details/:productId" exact component={DetailPage}></Route>
      <Route path="/faqs" exact component={FaqsPage}></Route>
      <Route path="/support" exact component={Support}></Route>
      <Route path="/our-stores" exact component={OurStores}></Route>
      <Route path="/terms-conditions" component={TermsConditions}></Route>
      <Redirect to="/" />
    </Switch>
  );
}