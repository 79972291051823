import { Link } from 'react-router-dom';
//import product from '../assets/traje1.jpg';
import '../styles/carrouselProducts.scss';

interface ITuxedoProps {
  tuxedo?: any
}

export const ProductCard = (props: ITuxedoProps) => {
  return <Link to={`/details/${props.tuxedo.productId}`} className='product-card'>
  <div className='image-card'><img src={props.tuxedo.img} alt="" /></div>
  {/*<div className='off'>10% Off</div>*/}
  <div className='name-price'>
    <p>{props.tuxedo.name}</p>
    <span>${props.tuxedo.price}</span>
  </div>
</Link>
}