export const Form = () => {
  return <div className='form'>
    <h3>KEEP IN TOUCH</h3>
    <form action="send_email.php" method="post" className='form-container'>
      <input type="email" name="email" id="email" placeholder='Enter your email adress' required/> 
      <input type="text" name="first_name" id="first_name" placeholder='Enter your name' required/> 
      <textarea name="message" id="message" placeholder='Enter your message' required></textarea>
      <div>
      <button className='btn-submit' type="submit">Send</button>
      </div>
    </form>
  </div>
}