import {useState} from 'react';
import '../../styles/faqs.scss';
import arrow from '../../assets/arrow-orange.svg';

export const Faq = (props: any) => {
  const [open, setOpen] = useState(false)
  const onClick = () => setOpen(!open)

  return <div className='faq' onClick={onClick}>
    <div className='question'>
      {props.question}
      <img src={arrow} alt="" />
    </div> 
    <div className={`answer ${open ? 'show-answer' : ''}`}>
      {props.answer}
    </div>
  </div>
}