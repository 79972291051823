import '../styles/header.scss';
//import brand from '../assets/tommy-logo-white1.png';
//import brandBlack from '../assets/tommy-logo-black1.png';
import { HamburgerSlider } from 'react-animated-burgers';
import {useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';

export const Header = (props: any) => {
  const changeBackground = props.background;

  const [ isActive, setIsActive] = useState(false)
  const toggleButton = () => setIsActive(!isActive)

  const responsiveNav = isActive ? 'nav-bar responsive' : 'nav-bar'
  const responsiveHamburguer = isActive ? '#111' : '#fff'
  const responsiveHeader = isActive ? 'header fix' : 'header'
  const responsiveLogo = isActive ? 'links black-link' : 'links'
   
  return <div className={responsiveHeader}>
    <div className='inner-header'>
      <div className='banner-store'>
        <p>Monday to Friday: open from 10am to 6pm - Saturdays: open from 10am to 4pm</p>
      </div>
      <div className={`${changeBackground ? responsiveNav : 'nav-bar white-nav-bar'}`} >
        <div className='brand'>
          <Link to='/' className={`${changeBackground ? responsiveLogo : 'links black-link'}`}>tommy´s tuxedos</Link>
        </div>
        <div className='hamburger'>
        <HamburgerSlider 
          isActive={isActive} 
          toggleButton={toggleButton} 
          buttonColor="transparent" 
          barColor={`${changeBackground ? responsiveHamburguer : '#111111'}`} 
        />
        </div>
        <div className={`nav-menu ${isActive ? 'showMenu' : '' }`}>
          <div className='nav-links'>
            <Link to='/catalog' className={`${changeBackground ? 'links' : 'links black-link'}`}>Catalog</Link>
            <Link to='/faqs' className={`${changeBackground ? 'links' : 'links black-link'}`}>Faqs</Link>
            <Link to='/home/#contact-us' smooth className={`${changeBackground ? 'links' : 'links black-link'}`}>Contact us</Link>
            <Link to='/tuxedos-box' smooth className={`${changeBackground ? 'links' : 'links black-link'}`}>Tuxedos Box</Link>
          </div>
          <div className='find-store-btn'>
             <Link to='/our-stores' className={`${changeBackground ? 'link-stores' : 'link-stores black-link-stores'}`}>Find Stores</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
}