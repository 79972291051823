import '../styles/howItWorks.scss';
import image1 from '../assets/howItWorkImg.jpg';
//import { Link } from 'react-router-dom';
import lupa from '../assets/lupa.svg';
import store from '../assets/storeIcon.svg';
import signo from '../assets/faqIcon.svg';

export const HowItWorks = () => {
  return <div className='how-it-works'>
    <div className='how-it-works-container'>
    <div className='image' data-aos="zoom-in" data-aos-delay="200">
        <img src={image1} alt="" />
      </div>
      <div className='text'>
        <div>
          <h2 data-aos="zoom-in" data-aos-delay="200">Rent your ideal tuxedo</h2>
          <div className='how-it-works-item' data-aos="zoom-in" data-aos-delay="300">
            <div className='item-icon'>
              <img src={lupa} alt="" />
            </div>
            <div className='item-text'>
              <h4>Browse</h4>
              <p>Explore our full catalog and select the one that is aligned with your expectations.</p>
            </div>
          </div>
          <div className='how-it-works-item' data-aos="zoom-in" data-aos-delay="300">
            <div className='item-icon'>
              <img src={store} alt="" />
            </div>
            <div className='item-text'>
            <h4>Visit</h4>
              <p>We are waiting for you at our stores located in Miami and Miami Lakes.</p>
            </div>
          </div>
          <div className='how-it-works-item' data-aos="zoom-in" data-aos-delay="300">
            <div className='item-icon'>
              <img src={signo} alt="" />
            </div>
            <div className='item-text'>
              <h4>Faqs</h4>
              <p>Have a question or need help? You can check our faqs section or click on the support link to contact us.</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
}