import '../styles/about.scss';
import about from '../assets/about.jpg';
import { Link } from 'react-router-dom';
import { Button } from './button';

export const About = () => {
  return <div className="about">
    <div className="about-container">
      <div className='about-text' data-aos="zoom-in" data-aos-delay="500">
        <span>since 1976</span>
        <h2>tommy´s tuxedos</h2>
        <p>Tommy has been helping our customers for more than 45 years to find what they need for those unique and special moments in their lives. We offer a vast catalog with many different designs and accessories with personalized service from our professionals.</p>
        <Link to='/catalog' className='about-link-btn'>
          <Button text='Designs'/>
        </Link>
      </div>
      <div className='img-about' data-aos="zoom-in" data-aos-delay="600">
        <img src={about} alt="" />
      </div>
    </div>
  </div>
}