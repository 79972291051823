import '../styles/cover.scss';
import coverImg from '../assets/portada-tommys.jpeg';
import { Button } from './button';
import { Link } from 'react-router-dom';
import coverImgResponsive from '../assets/portada-tommys-responsive.jpg';

export const Cover = () => {
  return <div className='cover'>
    <div className='cover-container'>
      <img src={coverImg} className='desktop' alt="" />
      <img src={coverImgResponsive} className='img-responsive' alt="" />
      <div className='call-to-action'>
        <h1 data-aos="zoom-in" data-aos-delay="400">Look fabulous and enjoy<br /> your unique moment</h1>
        <p data-aos="zoom-in" data-aos-delay="600">Find the perfect tuxedo for your event</p>
        <Link to='/catalog' data-aos="zoom-in" data-aos-delay="900">
          <Button text='Rent now'/>
        </Link>
      </div>
    </div>
  </div>
}