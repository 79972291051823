import { Delivery } from '../components/delivery';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { ProductCard } from '../components/productCard';
import { Stores } from '../components/stores/stores';
import { Tuxedos } from '../mocks/tuxedos';
import '../styles/catalog.scss';
import backCatalog from '../assets/backCatalog.jpg';
import line from '../assets/line.svg';
import { Form } from '../components/form';

export const Catalog = () => {
  window.scrollTo(0,0);
  
  return <div>
    <Header />
    <div className='all-colection-page'>
      <div className='all-colection-head'>
        <img src={backCatalog} alt="" />
        <div className='all-catalog-title'>
          <h3>all colection</h3>
          <span><img src={line} alt="" /></span>
          <p>Explore our full catalog</p>
        </div>
      </div>
      <div className='catalog-categories'>
        <h2>Adults & Kids</h2>
      </div>
      <div className='grid-catalog'>
        {Tuxedos.map((props: any) => <ProductCard tuxedo={props}/>)}
      </div>
    </div>
    <div className='delivery-data'>
      <div>
      <Form />
      </div>
      <div> 
        <Stores />
      </div>
    </div>
    <Footer />
  </div>
}