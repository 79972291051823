import { Footer } from '../components/footer';
import { Header } from '../components/header';
import '../styles/tuxBox.scss';
import boxImg from '../assets/tuxBox.jpg';
import box from '../assets/box.jpg';
import { Button } from '../components/button';
import { HashLink as Link } from 'react-router-hash-link';

export const TuxBox = () => {
  window.scrollTo(0,0);
  
  return <div>
    <Header />
   <div className='tux-box'>
        <div className='tux-box-container'>
            <img src={boxImg} alt="" />
            <div className='box-call-to-action'>
                <h1 data-aos="zoom-in" data-aos-delay="400">Rent exceptional tuxedos<br className='salto'/> delivered to your door</h1>
                <p data-aos="zoom-in" data-aos-delay="600"> Expert guidance on your perfect look</p>
                <Link to='/tuxedos-box/#box-contact' data-aos="zoom-in" data-aos-delay="900" smooth className='button btn-black'>Contact us</Link>
            </div>
        </div>
   </div>
   <div className='rent-box-data'>
      <div id="box-contact"></div>
      <div className='rent-box-text'>
          <h2>Effortless elegance,<br className='salto'/> delivered to you</h2>
          <p>Rent personalized tuxedos with doorstep delivery. Elevate your style for every special moment with our hassle-free service.</p>
          <div className='rent-contact-data'>
            <h4>Get in touch with us for guidance:</h4>
            <p><b>Email:</b>  <a href="mailto:tommystuxedosbox@gmail.com">tommystuxedosbox@gmail.com</a></p>
            <p><b>Phone:</b>   <a href="tel:3055534416">305-553-4416</a> / <a href="tel:3055129122">305-512-9122</a></p>
          </div>
      </div>
      <div className='rent-box-img'> <img src={box} alt="" /></div>
   </div>
    <Footer />
  </div>
}