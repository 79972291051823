import { Link } from 'react-router-dom';
import '../styles/footer.scss';
import face from '../assets/faceLink.svg';
import insta from '../assets/instLink.svg';

export const Footer = () =>{
  return <div className="footer">
    <div className="footer-container">
      <div className='footer-link'>
        <div>
          <h4>catalog</h4>
          <div className=''>
            <Link to='/catalog' className='link'>Full Catalog</Link>
          </div> 
        </div>
        <div>
          <h4>support</h4>
          <div className=''>
            <Link to='/faqs' className='link'>Faqs</Link>
            <Link to='/contact-us' className='link'>Contact Us</Link>
          </div> 
        </div>
        <div>
          <h4>find us</h4>
          <div className=''>
            <Link to='/our-stores' className='link'>Our Stores</Link>
          </div> 
        </div>
      </div>
      <div className='footer-brand'>
        <a href='https://www.facebook.com/tommys.tuxedos.9' target="_blank"><img src={face} alt="" /></a>
        <a href='https://www.instagram.com/tommystuxedos/' target="_blank"><img src={insta} alt="" /></a>
      </div>
    </div>
    <div className='rights'>Copyright © 2022 Tommys Tuxedos - All Rights Reserved |  <Link to='/terms-conditions' className='link temrs'>Terms & Conditions</Link></div>
  </div>
}