import { useState } from "react";
import { Header } from "../components/header";
import { Location } from "../components/stores/location";
import '../styles/stores.scss';

export const OurStores = () => {
  window.scrollTo(0,0);

  const [toggleState, setToggleState] = useState(1)
  const toggleTab = (index: any) =>{
    setToggleState(index)
  }
  
  return <div>
    <Header />
    <div className='our-stores-page'>
      <div className='location-container'>
        <h3>our stores</h3>
        <div className="location-one">
          <Location 
            city='Miami Location' 
            adress='890 S.W. 87 Avenue, Miami, FL 33174 - Westchester local 8' 
            number='305.553.4416' 
            linkBlue='GO WITH GOOGLE MAPS'
            arrow={true}
            linkMaps="https://www.google.com.ar/maps/place/890+SW+87th+Ave,+Miami,+FL+33174,+EE.+UU./@25.7612901,-80.3388451,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9b8e38c5a47d3:0xf758b7409c570ac0!8m2!3d25.7612853!4d-80.3366564"
          />
        </div>
        <div className="location-one">
          <Location 
            city='Miami Lakes / Hialeah Location' 
            adress='17690 N.W. 78 Avenue, Hialeah, FL 33015 - Miami Lake local 101' 
            number='305.512.9122' 
            linkBlue='GO WITH GOOGLE MAPS'
            arrow={true}
            linkMaps='https://www.google.com.ar/maps/place/17690+NW+78th+Ave,+Hialeah,+FL+33015,+EE.+UU./@25.9342781,-80.3297363,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9a4e6c7d9c9a5:0x37bb5335108d1b66!8m2!3d25.9342733!4d-80.3275476'
          /> 
        </div>
        <div className="stores-open">
          <h4>Stores Open</h4>
          <p>Monday to friday: from 10am to 6pm</p>
          <p>Saturday: from 10am to 4pm</p>
        </div>
      </div>
      <div className='map'>
      <div className="maps-tab">
          <div 
            className={`${toggleState === 1 ? 'tab' : 'tab bold'}`} 
            onClick={() => toggleTab(1)}>Miami</div>
          <div 
            className={`${toggleState === 2 ? 'tab' : 'tab bold'}`} 
            onClick={() => toggleTab(2)}>Miami Lakes</div>
        </div>
        <div className={`${toggleState === 1 ? 'showMap' : 'none'}`}>
          <iframe width="100%" height="600" frameBorder="0" title="tom-1" src="https://maps.google.com/maps?width=800&amp;height=800&amp;hl=en&amp;q=890%20S.W.%2087%20Avenue,%20Miami,%20FL%2033174%20Miami+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
        <div className={`${toggleState === 2 ? 'showMap' : 'none'}`}>
          <iframe width="100%" height="600" frameBorder="0" title="to-2" src="https://maps.google.com/maps?width=800&amp;height=800&amp;hl=en&amp;q=17690%20N.W.%2078%20Avenue,%20Hialeah,%20FL%2033015%20Miami+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
      </div>
    </div>
  </div>
}