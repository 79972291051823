import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './components/routes';
import './styles/defaults.scss';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { PopUpMessage } from './components/PopUpMessage';
//import { PopUpMessage } from './components/PopUpMessage';

function App() {
  useEffect(() => {
    Aos.init({duration: 500, once: true});
  }, []);
  
  return (
    <Router>
      <PopUpMessage />
      <Routes />
    </Router>
  );
}

export default App;
