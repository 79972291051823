import { Link } from 'react-router-dom';
import '../../styles/faqs.scss';
import { Faq } from './faq';

export const Faqs = () => {
  return <div className='faqs'>
    <div className='faqs-container'>
      <h2 data-aos="zoom-in" data-aos-delay="200">faqs</h2>
      <div className='faqs-list'>
        <div data-aos="zoom-in" data-aos-delay="400">
          <Faq question='Do we have to pay a deposit?' answer='50 % of the total amount is required up front.' />
          <Faq question='Have you got plus size clothing?' answer='All sizes are available up to XXL' />
          <Faq question='How far in advance should I make a reservation?' answer='From 10 to 15 days before you need it' />
        </div>
        <div data-aos="zoom-in" data-aos-delay="600">
          <Faq question='Is it possible to rent a tuxedo the day before I need it?' answer='Of course, it is. We have stock and professional tailors to make it possible, but it would be advisable to do it in advance.' />
          <Faq question='What happens if I don’t return the tuxedo on time?' answer='$30 a day will be applied' />
          <Faq question='Have you got all sizes to rent or sell?' answer='We have. You don’t have to pay additional costs to get a custom-made tuxedo' />
        </div>
      </div>
      <Link to='/faqs' className='faqs-all-question'>view all question</Link>
    </div>
  </div>
}