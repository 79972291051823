import '../../styles/termsConditions.scss';
import { Footer } from '../footer';
import { Header } from '../header';


export const TermsConditions = () => {
  window.scrollTo(0,0);
  
  return <div>
    <Header />
    <div className='tc-container'>
      <div className='inner-tc'>
        <h2>Rental Terms and Conditions</h2><br /><br />
        <h3>1.	Rental Order</h3><br />
        <div className='item'>
          <h4>a.	Style change order</h4>
          <p>Change order fees may apply if the requested style change is made less than 1 week (7 days) before the event date (provided your original items have not yet shipped). Any permissible changes that raise the price of the complete rental package will be charged in full at time of change. For more details regarding cost, refer to ADDITIONAL CHARGES THAT ARE OR MAY BE ASSOCIATED WITH RENTALS.</p>
        </div><br />
        <div className='item'>
          <h4>b.	Authorization of Reservation Cost & Settlement</h4>
          <p>Customers must pay at least 50% of the rental price upfront, to secure a reservation. An order is considered committed only after you have selected styles, been fitted, final measurements taken, and funds paid toward your individual rental.
          </p>
        </div><br />
        <div className='item'>
          <h4>c.	Pick up and drop off</h4>
          <p>For orders being picked up in a store, you should expect your rental to be available for pick-up 1 day before your event.</p>
        </div><br />
        <div className='item'>
          <h4>d.	Alterations</h4>
          <p>Please be aware that we are only doing minimal alterations (sleeve length and pants length) to our tuxedos. </p>
        </div>
        <br /><br />
        <h3>2.	Return Policy for Rentals</h3><br />
        <div className='item'>
          <h4>a.	Return Date Rules</h4>
          <p>To avoid late fees, you must return your rental to the store the first working day after the event date.</p>
        </div><br />
        <div className='item'>
          <h4>b.	Property Left in Rental</h4>
          <p>We are not responsible for any personal property left in a returned rental or garment bag.</p>
        </div><br />
        <div className='item'>
          <h4>c.	Limitation of Liability</h4>
          <p>At time of rental pick up, please allow adequate time to review and try on your rental to ensure accuracy and fit. Our total liability for any claim or litigation arising from or related to the rental garments is limited to the rental charges actually paid by customer.</p>
        </div>
        <br /><br />



        <h3>3.	Additional Charges</h3><br />
        <div className='item'>
          <h4>a.	Additional Charges that are or may be associated with Rental</h4>
          <p>
            i.	A deposit is required for in-store reservations. Customers must pay at least 50% of the rental price upfront, to secure a reservation.
            <br />ii.	Style Change Order Fee (style changes made less than 7 days before the event): Per order $60.00
            <br />iii.	Late Return Fee: Per day $30.00. Late fee will be accrued daily and charged in a lump sum, upon the receipt of the late garments, up to the full replacement cost of the applicable garment. If a garment is returned more than 21 days late, customer will be charged the full replacement cost. See Replacement Costs chart, for specific costs.
            <br />iv.	Cancellation Fee/No-Shows: 
            <br /><br />1.	Cancellations made 1 week (7 days) before the pick-up won’t imply any extra fees. Should you wish to cancel your order you will receive a refund of 50% of the amount paid or a full store credit valid up to one (1) year after the cancellation. Best efforts will be made to process refunds (a) on a debit card, and/or (b) to the original credit card after 180 days If we cannot process the refund back to the original form of tender, or if we have insufficient funds in the register, then you may take your refund in the form of store credit (voucher).
            Cancellations made less than 7 days in advance, will imply extra costs as described below:
            <br />a.	Wedding groups: Per group $40.00. 
            <br />b.	Individuals: Per order $20.00
            <br /><br />2.	If a customer picking up in-store fails to cancel a rental prior to the event date, they agree to forfeit any and all amounts paid.
            <br />v.	Taxes: Prices do not include applicable taxes, which will be charged to the order.
            <br />vi.	Lost/Missing Item Fee: If we determine that a rental is damaged beyond repair, or your rental is returned with missing or lost items, you will be charged a lump sum for the replacement cost according to our Replacement Costs.
          </p>
        </div><br />
        <div className='item'>
          <h4>b.	Replacement Cost</h4>
          <p>
            •	Coats: $110
            <br />•	Pants: $60
            <br />•	Vests / cummerbunds: $40
            <br />•	Shirts: $25
            <br />•	Shoes: $30
            <br />•	Accessories: $10
            -	Studs & Cuff Links
            -	Neckwear: Ties / bowties 
            -	Suspenders
          </p>
        </div>
        <br /><br />
        
        <h3>4.	Rental Pricing</h3><br />
        <div className='item'>
          <h4>a.	Complete package pricing: </h4>
          <p>Complete rental package prices include coat, pants, shirt, tie, cummerbund / vest / suspenders and shoes. Pricing is based on the complete package price shown for the coat style selected. The individual price of any item not ordered won’t be deducted from the total package price. Pricing does not reflect promotions, and promotions may be subject to limitations, including minimum package requirements.</p>
          <br />
          <p>•	Kids Shoes Sizes: please note that not all kids’ shoes sizes are available. Tommy’s Tuxedos reserves the right to not provide the shoes if not available.</p>
        </div>
        <br /><br />

        <h3>5.	Rental Promotions</h3><br />
        <div className='item'>
          <p>All offers can be modified or canceled anytime and are subject to product availability. Selection varies by store.</p>
          <br />
          <p>•	War Veterans: 10% discount applies. </p>
          <p>•	Weddings: on more than 9 rentals, one rental will be for free.</p>
        </div>
        
      </div>
    </div>
    <Footer/>
    </div>
}
