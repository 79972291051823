import '../../styles/carrouselProducts.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Tuxedos } from '../../mocks/tuxedos';
import { ProductCard } from '../productCard';

export const Carrousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 799, min: 299 },
      items: 2
    }
  };

  const topArticles = Tuxedos.filter((tux) => tux.top === 'topSale')

  return <div className='carrousel'>
  <Carousel responsive={responsive} itemClass="carousel-item-padding-40-px">
    {topArticles.map((props: any) => <ProductCard tuxedo={props}/>)}
  </Carousel> 
</div>
}