import { Header } from "../components/header";
import '../styles/details.scss';
import { Button } from "../components/button";
import { Carrousel } from "../components/carrousel/carrousel";
import { Footer } from "../components/footer";
import { useParams } from 'react-router';
import {useState, useEffect} from 'react'; 
import { Tuxedos } from "../mocks/tuxedos";
import { HashLink as Link } from 'react-router-hash-link';
import { Sale } from "../components/sale";


export const DetailPage = () => {
  const {productId} = useParams<{productId: string}>()
  const [productDetail, setProductDetail] = useState<any>({})

  useEffect(() => {
    const productSelected = Tuxedos.find((product: any) => product.productId === +productId as any);
    setProductDetail(productSelected)
  }, [productId])

  window.scrollTo(0,0);

  return <div>
    <Header />
    <div className="detail-product-page">
      <div className="detail-container">
        <div className="detail-product-img">
          <img src={productDetail.img} alt="" />
        </div>
        <div className="detail-product-data">
          <div className="detail-product-data-box">
            <h5>{productDetail.name}</h5>
            <span>${productDetail.price}</span>
            <p>{productDetail.description}</p>
          </div>
          <div className="rent-btn-box">
            <Link to='/home/#contact-us' smooth><Button text='Send enquiry' /></Link>
          </div>
          <div className="tuxedos-includes">
            <h6>Tuxedo includes</h6>
            <ul>
              <li>• Shoes</li>
              <li>• Shirt</li>
              <li>• Bow tie</li>
              <li>• Necktie</li>
              <li>• Waistcoat</li>
              <li>• Suspenders</li>
              <li>• Cuff links</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="more-products">
        <h3>other tuxedos</h3>
        <Carrousel />
      </div>
      <Footer />
    </div>
  </div>
}