import '../styles/button.scss';

export const Button = (props: any) => {
  const background = props.backColor;
  return <button  
    type={props.type} 
    className={`${background ? 'button' : 'button btn-black'}`} 
    onClick={props.onHandleClick}
    >
    {props.text}
  </button>
}